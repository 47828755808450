import $ from "jquery";
window.$ = window.jquery = $;
import React, { useEffect, useRef, useState } from "react";
import { easepick } from "@easepick/core";
import { LockPlugin, RangePlugin } from "@easepick/bundle";
import { useTranslation } from "react-i18next";
import { Form, useLocation } from "react-router-dom";

const FindRoom = () => {
  const dateRangePicker = useRef(null);
  const [easepickHideFlag, setEasepickHideFlag] = useState(0);
  let flag = 0;
  const propertyID = 13041;

  const { t, i18n } = useTranslation();
  const [arrRoom, setArrRoom] = useState([
    { id: 29799, title: "Twin Park View Suites" },
  ]);

  useEffect(() => {
    // get_data();
    $(".custom_select select").niceSelect();
    const picker = new easepick.create({
      element: dateRangePicker.current,
      css: [
        "https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css",
        "/css/easepicker.css",
      ],
      plugins: [RangePlugin, LockPlugin],
      RangePlugin: {
        tooltip: true,
      },
      LockPlugin: {
        minDate: new Date(),
        inseparable: true,
      },
      // inline: true,
      format: "DD-MM-YYYY",
      zIndex: 1,
    });

    $("#dateRange").on("click", () => {
      $(".fab-container").css("z-index", 1);
    });

    picker.on("view", (e) => {
      if (picker.isShown() == true) {
        $(".fab-container").css("z-index", 1);
      } else {
        $(".fab-container").css("z-index", 2);
      }
    });
  }, []);

  useEffect(() => {
    if (flag == 2) {
      $("#main").css("z-index", 2);
      flag = 0;
    }
  }, [flag]);

  const submit = (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);
    const [startDate, endDate] = data.date_booking.split(" - ");

    const bookingUrl =
      "https://www.e1-booking.com/booking/booking-page.php?id=" +
      propertyID +
      "&roomid=" +
      data.rooms_booking +
      "&checkin=" +
      startDate +
      "&checkout=" +
      endDate;

    // window.open(bookingUrl, "_blank");
  };

  // async function get_data() {
  //   var response = await base.request(base.url_api + "/room");

  //   if (response != null) {
  //     if (response.status == "success") {
  //       setArrRoom(response.data);
  //     } else base.show_error(response.message);
  //   } else console.log("Server Error");
  // }

  return (
    <div
      id="findRoom"
      className="card container position-relative p-4 mt-4"
      style={{ zIndex: 2 }}
    >
      <Form action="/" onSubmit={(e) => submit(e)}>
        <div className="row">
          <div className="col-12 col-lg">
            <div className="form-group mb-0">
              <label className="form-label">{t("findRoom.selectRoom")}</label>
              <div className="custom_select mb-0">
                <select
                  className="wide"
                  name="rooms_booking"
                  id="rooms_booking"
                >
                  <option value="">{t("findRoom.selectRoom")}</option>
                  {arrRoom.map((room, index) => (
                    <option key={index} value={room.id}>
                      {room.title}
                    </option>
                  ))}
                  {/* <option value="Double Room">Double Room</option>
									<option value="Superior Room">Superior Room</option>
									<option value="Junior Suite">Junior Suite</option> */}
                </select>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg mt-4 mt-lg-0">
            <div className="form-group mb-0">
              <label className="form-label">{t("findRoom.dateOfStay")}</label>
              <div className="position-relative">
                <input
                  ref={dateRangePicker}
                  type="text"
                  name="date_booking"
                  id="dateRange"
                  className="form-control pe-5"
                  placeholder={t("findRoom.selectDate")}
                  style={{ fontSize: 14, letterSpacing: 1.5 }}
                />
                <i
                  className="bi bi-calendar-range position-absolute text-dark p-3"
                  style={{
                    top: "50%",
                    right: 0,
                    transform: "translateY(-50%)",
                  }}
                ></i>
              </div>
            </div>
          </div>

          {/* <div className="col">kasjdflkjalsdkf</div>
					<div className="col">asjdlfkafk</div> */}
          <div className="col-auto d-flex align-items-end mt-4 mt-lg-0">
            <input
              className="btn_1 outline"
              type="submit"
              value={t("findRoom.bestDeals")}
              id="submit-contact"
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default FindRoom;
